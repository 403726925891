import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 60 min. `}<meta itemProp="prepTime" content="PT60M" /></h5>
    <h5>{`Cook time: 20 min. `}<meta itemProp="cookTime" content="PT20M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`4-6`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <h3>{`Chicken Saté`}</h3>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Chicken thighs, 4-6 pieces cubed</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Shallot, 1 diced</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Garlic, 2-3 cloves diced</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Coriander, ~1 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Cumin, ~1 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Turmeric, ~1/4 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Candlenut, 1/2 grated</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Indonesian kecap, 2-3 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Sugar, ~1 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salt and pepper, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">
  Fried shallots, as topping for individual serving
        </span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">
  Fresh vegetables, like bell pepper, cucumber, and/or tomatoes.
        </span>
      </li>
    </ul>
    <h3>{`Peanut Sauce`}</h3>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Vegetable oil, 1 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Shallot, 1 diced</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Garlic, 1-2 cloves diced</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Chunky peanut butter, 1 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Sambal oelek, 1-2 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Indonesian kecap, ~1 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Lemon, juice from half lemon</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Sugar, 1 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salt, to taste</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Bamboo skewers</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Medium mixing bowl</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Zester</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">9x13 glass pan</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Small pot</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Lemon squeezer</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Cut chicken into 1 in. pieces</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Dice shallots</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Dice garlic</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Grate candlenut</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Soak skewers, at least 1 hour</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Grease glass pan</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Slice vegetables for serving</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`In mixing bowl, combine: chicken cubes, shallots, garlic, coriander, cumin, turmeric, candlenut, kecap, sugar, salt, and pepper. Cover and marinate overnight.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`To make peanut sauce, heat small pot on medium-low, add oil and cook shallots and garlic until soft.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Mix in peanut butter, sambal, kecap, lemon juice, sugar and salt. Cook on low heat until hot, set aside.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Skewer chicken, arrange in glass pan.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Bake at 375F 10 minutes per side.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Broil for 2 minutes at the end.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Serve with fried shallots, fresh vegetables.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      